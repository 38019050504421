.navbar{
    height: 50px;
    background-color: #fa7406;
    display: flex;
    justify-content: center;
}

.navContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    max-width: 1024px;
}
.logo{
    padding: 20px;
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-decoration: none;
}
.logo-new{
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-decoration: none;
}
.navButton{
    margin: 20px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    color: #003580;
    font-size: 15px;
}