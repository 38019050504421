.featured{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    z-index: 1;
  }
  
  .featuredItem{
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    flex:1
  }
  .featuredItem:hover{
    cursor: pointer;
    box-shadow: 10px 10px 5px rgba(0, 0, 80, 0.5);
  }
  
  .featuredImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .featuredTitles{
    position: absolute;
    bottom: 20px;
    left: 20px;
  }