.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://wallpapers.com/images/file/free-travel-wallpaper-photo-35b3je7ble2ls45t.jpg');
    background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;

  }
  
  .lContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .lInput{
    height: 30px;
    padding: 10px;
  }
  
  .lButton {
    border: none;
    padding: 10px 20px;
    background-color: #0071c2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .lButton:disabled{
    background-color: #0071c28c;
    cursor: not-allowed;
  }