.contact-us {
  background-image: url(https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-size: cover;
  background-position: center;
  position: relative;
  padding-bottom: 60px;
}

.section-header {
  text-align: center;
}
.section-header-wrapper{
  background-color: #003580;
  padding: 60px 0;
}
.section-header p {
  color: #ffffff;
}
.section-header h1 {
  color: #ffffff;
  margin-bottom: 12px;

}


.contact-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 60px;
  width: 100%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #f97222;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #333;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  width: 100%;
}
form#contact-form {
  padding: 40px;
}
.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"] {
  width: 100%;
  background: #f97222;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #f97222;
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
  background: #fff;
  color: #f97222;
}

@media (max-width: 991px) {
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
    grid-template-columns: 1fr;
  }

  .contact-form {
    width: 100%;
  }
}
